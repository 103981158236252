<template>
  <div class="warehouse-page">
    <el-form ref="dataForm" inline class="bysearchForm">
      <div class="searchLine">
        <el-form-item prop="companyId" class="typeInput" v-if="ListId === 2">
          <el-select
            v-model="dataForm.companyId"
            placeholder="公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="orgId" class="typeInput" v-if="ListId === 1">
          <el-select
            v-model="dataForm.orgId"
            placeholder="供应商"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="periodId" class="typeInput">
          <el-select
            v-model="dataForm.periodId"
            placeholder="时段"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="state">
          <el-select
            v-model="dataForm.state"
            placeholder="状态"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="week" class="typeInput">
          <el-select
            v-model="dataForm.week"
            placeholder="星期"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.week"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDetailList()">查询</el-button>
          <el-button
            v-if="isAuth('tc:orgperiodcompany:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:orgperiodcompany:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            >批量删除</el-button
          >
          <el-button
            v-if="isAuth('tc:orgcompanygoods:update')"
            type="primary"
            @click="updateGoods()"
            :disabled="dataListSelections.length <= 0"
            >批量上架商品</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="main-container">
      <div class="aside-wrap">
        <el-select
          v-model="ListId"
          @change="getDataList(1)"
          class="sideTopSelect"
        >
          <el-option
            v-for="(item, index) in ListSelections"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-table
          height="calc(100vh - 210px)"
          :data="dataList"
          ref="dataList"
          border
          :row-class-name="tableRowClassName"
          @selection-change="$selectionChange($event, 'dataList')"
          @row-click="$clickRow($event, 'detailList', getDetailList($event))"
          @select="$select(arguments, 'dataList')"
          @select-all="$clearSelection('dataList')"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            :label="ListName"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'dataList')"
          @current-change="$currentChange($event, 'dataList')"
          :current-page="dataListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="dataListSize"
          :total="dataListCount"
          layout="total, sizes, prev, pager, next"
        />
      </div>
      <div class="main-wrap">
        <el-table
          :data="detailList"
          border
          stripe
          ref="detailList"
          v-loading="detailListLoading"
          @row-click="$clickRow($event, 'detailList')"
          @selection-change="$selectionChange($event, 'detailList')"
          style="width: 100%"
          @select-all="handleSelectAll"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          >
          </el-table-column>
          <el-table-column
            prop="orgName"
            header-align="center"
            align="center"
            label="供应商名称"
            v-if="ListId === 1"
          >
          </el-table-column>

          <el-table-column
            prop="companyName"
            header-align="center"
            align="center"
            label="公司"
            v-if="ListId === 2"
          >
          </el-table-column>

          <el-table-column
            prop="periodWithTimeName"
            header-align="center"
            align="center"
            label="时段"
          >
          </el-table-column>

          <el-table-column
            prop="weeks"
            header-align="center"
            align="center"
            label="星期"
          >
          </el-table-column>

          <el-table-column
            prop="startDate"
            header-align="center"
            align="center"
            label="生效时间"
          >
          </el-table-column>

          <el-table-column
            prop="endDate"
            header-align="center"
            align="center"
            label="终止日期"
          >
          </el-table-column>

          <el-table-column
            prop="createdAt"
            header-align="center"
            align="center"
            label="创建日期"
          >
          </el-table-column>

          <el-table-column
            prop="createdBy"
            header-align="center"
            align="center"
            label="创建者"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            width="220"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                v-if="isAuth('tc:orgperiodcompany:info')"
                type="text"
                size="small"
                @click="$dialog('addOrUpdate', scope.row.id, true)"
                >查看</el-button
              >
              <el-button
                v-if="isAuth('tc:orgperiodcompany:update')"
                type="text"
                size="small"
                @click="$dialog('addOrUpdate', scope.row.id)"
                >修改</el-button
              >
              <el-button
                v-if="isAuth('tc:orgperiodcompany:delete')"
                type="text"
                size="small"
                @click="deleteHandle(scope.row.id)"
                >删除</el-button
              >
              <el-button
                v-if="isAuth('tc:orgcompanygoods:update')"
                type="text"
                size="small"
                @click="
                  $dialog(
                    'companyGoodsOnShelves',
                    scope.row.orgId,
                    scope.row.id,
                  )
                "
                >上架商品</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <list-footer>
          <el-pagination
            background
            @size-change="$sizeChange($event, 'detailList')"
            @current-change="$currentChange($event, 'detailList')"
            :current-page="detailListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="detailListSize"
            :total="detailListCount"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </list-footer>
      </div>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDetailList"
    />
    <company-goods-on-shelves
      v-if="companyGoodsOnShelvesVisible"
      ref="companyGoodsOnShelves"
      @success="getDetailList"
    />
  </div>
</template>

<script>
import { threeListMixin, listMixin, normal } from '@/mixins';
import AddOrUpdate from './org-period-company-add-or-update';
import CompanyGoodsOnShelves from './company-goods-on-shelves';

export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      dataForm: {
        orgId: '',
        companyId: '',
        periodId: '',
        state: [0, 1],
        week: '',
      },
      dataList: [],
      detailList: [],
      companyGoodsOnShelvesVisible: false,
      dataListSelections: [],
      companyList: [],
      orgList: [],
      periodList: [],
      addOrUpdateVisible: false,
      ListId: 1,
      ListName: '',
      ListSelections: [
        { id: 1, name: '公司列表' },
        { id: 2, name: '供应商列表' },
      ],
      stateList: [
        { id: 0, state: '待生效' },
        { id: 1, state: '生效中' },
        { id: 2, state: '已终止' },
      ],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
    };
  },
  components: {
    AddOrUpdate,
    CompanyGoodsOnShelves,
  },
  activated() {
    this.getDataList();
  },
  created() {
    this.getPeriodList();
    this.getOrgList();
    this.getCompanyList();
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      if (row.isUse === 0) return 'danger-row';
    },
    getDataList(type) {
      if (type === 1) {
        this.dataForm.companyId = null;
        this.dataForm.orgId = null;
      }
      if (this.ListId === 1) {
        this.getCompanyPage();
      } else {
        this.getOrgPage();
      }
    },
    getCompanyPage() {
      this.$api({
        url: '/tc/company/query',
        params: {
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.list.records;
            this.dataListCount = data.list.total;
            this.ListName = this.ListSelections[0].name;
            this.dataForm.companyId = data.list.records[0].id;
            this.getDetailList();
          } else {
            this.dataList = [];
            this.dataListCount = 0;
          }
        },
      });
    },
    getOrgPage() {
      this.$http({
        url: `/cc/org/page`,
        method: 'get',
        params: {
          type: 0,
          isUse: 1,
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
          this.dataListCount = data.data.total;
          this.ListName = this.ListSelections[1].name;
          this.dataForm.orgId = data.data.items[0].id;
          this.getDetailList();
        } else {
          this.dataList = [];
          this.dataListCount = 0;
        }
      });
    },
    getOrgList() {
      this.$http({
        url: `/cc/org/all`,
        method: 'get',
        params: { type: 0, isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },

    getCompanyList() {
      this.$http({
        url: `/tc/company/combo`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    // 获取数据列表
    getDetailList(row) {
      if (this.ListId === 1) {
        this.dataForm.companyId = row ? row.id : this.dataForm.companyId;
      } else {
        this.dataForm.orgId = row ? row.id : this.dataForm.orgId;
      }
      this.$api({
        url: '/tc/orgperiodcompany/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          ...this.dataForm,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.detailList = data.page.records;
            this.detailListCount = data.page.total;
          } else {
            this.dataList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些公司进行删除操作?`,
        url: '/tc/orgperiodcompany/delete',
        data: ids,
        after: () => {
          this.getDetailList();
        },
      });
    },
    // 批量上架商品
    updateGoods(id) {
      let ids = id
        ? [id]
        : this.dataListSelections
            .map((item) => {
              return item.id;
            })
            .join(',');
      let firstData = this.dataListSelections[0].orgId;
      let orgId = this.dataListSelections
        .map((item) => {
          return item.orgId;
        })
        .filter((item) => firstData !== item);
      if (orgId?.length) {
        this.$message.error(`请选择相同的供应商进行批量操作`);
      } else {
        this.$dialog('companyGoodsOnShelves', firstData, ids);
      }
    },
    getPeriodList() {
      this.$http({
        url: `/cc/global-period/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
  },
};
</script>
